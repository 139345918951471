import React, { Component } from 'react'
import { getOs } from '../../utils/utility'
import { gethealthcard, getProgramInfo, getRxSimpleShareData } from '../ApiCall'
export default class RightDrawer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      link: 'https://members.myflexcare.com/login/member',
      fliptLink: '',
      pharmaShow: false,
      isRevive: false,
      reviveLink: ''
    }
  }

  componentDidMount() {
    gethealthcard().then(resp => {
      let data = {
        memberSSN: resp.data.memberIdCardList[0].memberSSN,
        type: 'family'
      }
      getRxSimpleShareData(data).then(res => {
        if (res && Array.isArray(res)) {
          res.map(value => {
            if (value.addon.includes('RxSimpleShare') && value.status === 'AC')
              this.setState({ pharmaShow: true }, () => {
                localStorage.setItem('popupShow', 'true')
              })
            if (value.addon.includes('Health Tools') && value.status === 'AC')
              this.setState({ isTelemed: true }, () => {
                localStorage.setItem('popupShow', 'true')
              })
            if (value.addon.includes('UHF Homebase') && value.status === 'AC')
              this.setState({ isHomebase: true }, () => {
                localStorage.setItem('isHomebase', 'true')
              })
            if (value.addon.includes('UHSRx') && value.status === 'AC')
              this.setState({ isRevive: true }, () => {
                localStorage.setItem('isRevive', true)
              })
          })
        }
      })
      getProgramInfo()
        .then(res => {
          if (res.data && res.data.programInfo) {
            var str = res.data.programInfo.programName
            if (str.includes('+')) {
              var str1 = res.data.programInfo.programName
              var res1 = str1.split(' ')
              this.setState({ programname: res1[0], programname2: ' with ' + res1[2], showwithacsm: true })
            } else {
              this.setState({ programname: res.data.programInfo.programName, showwithacsm: false })
            }
          } else {
            this.setState({ loader: false })
          }
        })
        .catch()
    })
    var os = getOs()
    console.log('==========RIGHT DRAWER IS====', os)

    if (os == 'Mac OS') {
      this.setState({
        link: 'https://members.myflexcare.com/login/member',
        fliptLink: 'https://apps.apple.com/us/app/southern-scripts/id938009409',
        reviveLink: 'https://apps.apple.com/us/app/revivehealth-mobile/id6450846042'
      })
    }
    if (os == 'iOS') {
      this.setState({
        link: 'https://apps.apple.com/us/app/mdlive/id839671393',
        fliptLink: 'https://apps.apple.com/us/app/southern-scripts/id938009409',
        reviveLink: 'https://apps.apple.com/us/app/revivehealth-mobile/id6450846042'
      })
    }
    if (os == 'Windows') {
      this.setState({
        link: 'https://members.myflexcare.com/login/member',
        fliptLink: 'https://member.liviniti.com/home',
        reviveLink: 'https://member.myrevive.health/'
      })
    }
    if (os == 'Android') {
      this.setState({
        link: 'https://play.google.com/store/apps/details?id=com.mdlive.mobile',
        fliptLink: 'https://play.google.com/store/apps/details?id=org.yappymobile.southernscripts&pli=1',
        reviveLink: 'https://play.google.com/store/apps/details?id=health.revive.mobile&hl=en_US&pli=1'
      })
    }
    if (os == 'Linux') {
      this.setState({
        link: 'https://members.myflexcare.com/login/member',
        fliptLink: 'https://member.liviniti.com/home',
        reviveLink: 'https://member.myrevive.health/'
      })
    }
  }

  opentelemed() {
    window.open(this.state.link)
  }
  openFlipt = () => {
    window.open(this.state.fliptLink)
  }
  openRevie = () => {
    window.open(this.state.reviveLink)
  }
  openCuramLifeCare = () => {
    var os = getOs()
    if (os == 'Mac OS' || os == 'Windows' || os == 'Linux')
      window.open('https://www.universalhealthfellowship.org/curamlife-care-coordination-app/')
    else if (os == 'iOS') window.open('https://apps.apple.com/us/app/curamlife/id1609101662')
    else if (os == 'Android') window.open('https://play.google.com/store/apps/details?id=com.curamlife')
  }
  render() {
    return (
        <div class="feature-container">
          {((localStorage.getItem('planId') !== '10051' &&
            localStorage.getItem('planId') !== '10052' &&
            localStorage.getItem('planId') !== '10053' &&
            localStorage.getItem('planId') !== '10061' &&
            localStorage.getItem('planId') !== '10062' &&
            localStorage.getItem('planId') !== '10063') ||
            (this.state.programname?.includes('ES') && this.state.isTelemed)) && (
            <div class="feature-item" onClick={() => this.openCuramLifeCare()}>
              <img src={require('../Images/CuramLife (2).svg')} alt="CuramLife Care Coordination App" />
              <p>CuramLife Care Coordination App</p>
            </div>
          )}
          {this.state.isHomebase && (
            <div class="feature-item">
              <img src={require('../Images/LeftDrawer Icon/CuramLife_Homebase_Icon.png')} alt="CuramLife Homebase App" />
              <p>CuramLife Homebase App<small>Medical Records<br />powered by Docsnap</small></p>
             
            </div>
          )}
          {this.state.pharmaShow && localStorage.getItem('CLIENT_ID') != '5742' && localStorage.getItem('CLIENT_ID') != '4599' && (
            <div class="feature-item" onClick={() => this.openFlipt()}>
              <img src={require('../../assets/images/pharma_benefits_icon_active.svg')} alt="UHSRx" />
              <p>RxSimpleShare</p>
            </div>
          )}
          {this.state.isRevive && localStorage.getItem('CLIENT_ID') != '5742' && localStorage.getItem('CLIENT_ID') != '4599' && (
            <div class="feature-item" onClick={() => this.openRevie()}>
              <img src={require('../../assets/images/pharma_benefits_icon_active.svg')} alt="UHSRx" />
              <p>UHSRx</p>
            </div>
          )}
          {((this.state.isTelemed && this.state.programname?.includes('ES')) || !this.state.programname?.includes('ES')) && (
            <div class="feature-item" onClick={() => this.opentelemed()}>
              <img src={require('../Images/Card/telemed_icon_active.svg')} alt="Telemedicine" />
              <p>Telemedicine</p>
            </div>
          )}
        </div>
    )
  }
}

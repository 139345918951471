import CloseIcon from '@material-ui/icons/Close'
import React, { Component } from 'react'
import { getAgentInfo, getContactData, gethealthcard, getNetworkName, getSendNeedsToContactAddress, getSourceID } from '../ApiCall'
import { isObject } from 'util'
import apiservice from '../../utils/apiservice'
export default class ContactInformationCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      digitalheathcard: null,
      plainId: null,
      provider: null,
      network: null,
      channel: null,
      contactNumber: null,
      cardId: null,
      showEmpId: false,
      agentemail: null,
      agentname: null,
      agentno: null,
      contactData:[]
    }
  }
  componentDidMount() {
    // this.gethealthcarddata()
    // this.getSourceIDbyemail()
    this.getContactInfoData();
  }

  async getContactInfoData(){
    let plainId = localStorage.getItem('planId');
    let sourceid = localStorage.getItem('sourceid');
    let member_ssn = localStorage.getItem('memberSSN');
    let client_id = localStorage.getItem('CLIENT_ID');
    if(!member_ssn || !sourceid || !client_id || !plainId){
      let mail = localStorage.getItem('userMail')
      var request = { email: mail }
      const res = await apiservice.post(process.env.REACT_APP_BASE_URL + 'v2/memberportal/idcard', request)
      member_ssn = res?.data?.memberIdCardList[0]?.memberSSN
      sourceid = res?.data?.memberIdCardList[0]?.memberId
      client_id = res?.data?.memberIdCardList[0]?.clientId
      localStorage.setItem('CLIENT_ID', res?.data?.memberIdCardList[0]?.clientId)
      localStorage.setItem('planId', res?.data?.memberIdCardList[0]?.planId)
    }
    getContactData(plainId,sourceid,member_ssn,client_id).then(
      res =>{
        console.log( res?.data?.response+" -----------");
        this.setState({contactData : res?.data?.response})
      }
    )
  }
  getSourceIDbyemail() {
    getSourceID().then(res => {
      console.log('Member source id issss in contact cardd', res.data.memberIdSource)
      this.agentInfoget(res.data.memberIdSource)
    })
  }

  agentInfoget(sourceid) {
    if (sourceid) {
      getAgentInfo(sourceid).then(res => {
        console.log('agent info get========', res)
        console.log('agent info get========', res.data.response) 
        if (res.data.response) {
          localStorage.setItem('AgentDetails', JSON.stringify(res.data.response))
          this.setState({
            agentemail: res.data.response.email,
            agentname: res.data.response.name,
            agentno: res.data.response.phone
          })
        } else {
        }
      })
    }
  }

  async gethealthcarddata() {
    gethealthcard().then(res => {
      if (res && res.data && res.data.memberIdCardList != null) {
        this.setState({ digitalheathcard: res.data, plainId: res.data.memberIdCardList[0].planId })
        this.getNetworkData(res.data.memberIdCardList[0].planId)
        this.setContactandCardID(res.data)
      } else {
      }
    })
  }

  setContactandCardID(data) {
    data.memberIdCardList[0].planInfo.map((data, index) => {
      if (data.idcardField == 'contact number') {
        this.setState({ contactNumber: data.fieldValue })
      }
      if (data.idcardField == 'card id') {
        this.setState({ cardId: data.fieldValue })
      }
    })
  }

  getNetworkData(plainid) {
    getNetworkName(plainid).then(res => {
      console.log('netowrok name againt plain id=======', res.data)
      this.setState({
        network: (res.data.provider_network === 'HLC' || res.data.provider_network === 'HL' || res.data.provider_network === 'HLCA' || res.data.provider_network === 'THS') ? 'PHCS' : res.data.provider_network,
        provider: res.data.provider_network
      })
      this.setState({
        loader: false
      })
    })
  }

  render() {
    return (
      <div class="contactinfocard">
        <div class="myneeds_top_container">
          <img src={require('../Images/LeftDrawer Icon/contact-info-icon-active.svg')} class="myneeds_header_image" />
          <div class="myneeds_header_text">Contact Information</div>
          {this.props.close ? <CloseIcon class="modal_close_cursor" onClick={this.props.onClick} /> : null}
        </div>

        {this.props.contactCardData && this.props.contactCardData.enable ? 
        (<>
            <div class="contact_infomiddiv text-left">
              {
                this.state.contactData?.map((contact,key)=>
                <div key={key}>
                  <div class="contactinfo_label">{contact.label}</div>
                  <div class="contactinfo_Value">{
                    (contact?.label === 'Your Agent Details:') ?
                    <div dangerouslySetInnerHTML={{__html:`${JSON.parse(contact?.value)?.name}<br>\n${JSON.parse(contact?.value)?.phone.replace(/^\+1|^(\+91)/, '')}<br>\n${JSON.parse(contact?.value)?.email}` }}/>
                    : Array.isArray(contact.value) ? (
                      contact.value.map((nestedContact, nestedKey) => (
                        <div key={nestedKey} style={{marginLeft: 15}}>
                          <div className="contactinfo_label">{nestedContact.label}</div>
                          <div className="contactinfo_Value">{nestedContact.value}</div>
                        </div>
                      ))
                    ) : (
                      contact.value
                    )}
                    </div>
                 </div>
                )
              }
            </div>
        </>): (
          <div style={{ padding: '35px' }}>
            <div className="tempModalTxt">
              We’re facing some technical difficulties, due to which this feature is currently unavailable. For support, call Member
              Services at {localStorage.getItem('CONTACT_NUMBER')}, Monday through Friday, 8.00am to 8.00pm CST.
            </div>
          </div>
        )}

        <div class="container contactcard_footer">
          {/* <div class="row">
                        <div class="col text-left">
                            <FaHeart color="rgba(0, 0, 0, 0.54)" size={16} />
                            <IoMdShare color="rgba(0, 0, 0, 0.54)" size={18} class="ml-4" />
                        </div>
                        <div class="col text-right">
                            <MdKeyboardArrowDown color="rgba(0, 0, 0, 0.54)" size={20} />
                        </div>
                    </div> */}
        </div>
      </div>
    )
  }
}
